import React from 'react'
import {Link, graphql} from 'gatsby'

import Layout from '../components/layout'
import * as CategoriesStyles from '../components/categories.module.css'


export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    category: sanityTag(id: {eq: $id}) {
        tagName
        
        posts {
            title
            titleTxt
            slug {
              current
            }
          }
    }
  }
`

const CategoryPostTemplate = props => {


  const {data = {}, errors} = props
  const {tagName, posts} = data.category || {}

  return (
    
    
    <div className='categories-page'>
    <Layout>
      <>
  
        {!data.category && <p>No category data</p>}
        <div title={tagName} />
        <section className={CategoriesStyles.mainContent}>


          {posts && (
            <React.Fragment>
              <h2>Research articles with tag: {tagName}</h2>
              <ul>
                { posts.map(post => (
                  <li key={post.slug.current}>
                    <div className={CategoriesStyles.articleLink}>  
                        <Link to={ `/research/${post.slug.current}`}>{post.title}</Link>
                    </div>
                    <p className={CategoriesStyles.subTitle}>{post.titleTxt}</p>
                  </li>))
                }
              </ul>
            </React.Fragment>)
          }
        </section>
      </>
    </Layout>
    </div>
  )
        
}

export default CategoryPostTemplate